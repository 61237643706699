<template>
  <div id="new-home">
    <Sec1 />
    <!-- <div class="section-1">
      <Section
    </div> -->

    <div class="section-2">
      <div class="sect-wrapper">
        <b-row
          class="sect-row"
          align-v="end"
          no-gutters>
          <b-col cols="12" xl="5">
            <img
              class="img-fluid princess"
              :src="images.sec2.princess"
              alt="Princess Panda taking a selfie"
            />
          </b-col>
          <b-col cols="12" xl="7">
            <h1 class="heading">
              MUSIC AND <br> MINDFULNESS
            </h1>
            <img
              class="img-fluid squad"
              :src="images.sec2.squad"
              alt="Wacky Princess Panda, PJ Panda, and Leo"
            />
          </b-col>
        </b-row>
      </div>
    </div>

    <div class="section-3">
      <div class="sect-wrapper">
        <b-row
          align-v="center"
          no-gutters>
          <b-col cols="12" xl="4">
            <img
              class="img-fluid left"
              :src="images.sec3.dancers1"
              alt="Girl Dancers"
            />
          </b-col>
          <b-col cols="12" xl="4">
            <h1 class="text-center">
              MOVEMENT
              <img
                class="ampersand-big"
                :src="home.ampersandBig"
                alt="Ampersand"
              />
              <br> MOTIVATION
            </h1>
            <img
              class="img-fluid middle"
              :src="images.sec3.squad"
              alt="Heathy Hip Hop Squad"
            />
          </b-col>
          <b-col cols="12" xl="4">
            <img
              class="img-fluid right"
              :src="images.sec3.dancers2"
              alt="Dancers"
            />
          </b-col>
        </b-row>
      </div>
    </div>

    <div class="section-4">
      <div class="sect-wrapper">
        <b-row
          class="sect-row"
          align-v="end"
          no-gutters>
          <b-col
            class="text-center"
            cols="12" xl="6"
            order="1" order-md="1" order-lg="1" order-xl="2">
            <h1>BECOME A <br> HEALTHY HIP HOP KID</h1>
            <img
              class="img-fluid cubcakes"
              :src="images.sec4.cubcakes"
              alt="Cubcakes Logo"
            />
          </b-col>
          <b-col
            class="text-right"
            cols="6" xl="3"
            order="2" order-md="2" order-lg="2" order-xl="1">
            <img
              class="img-fluid left"
              :src="images.sec4.kid1"
              alt="Boy"
            />
          </b-col>
          <b-col
            class="text-left"
            cols="6" xl="3"
            order="3" order-md="3" order-lg="3" order-xl="3">
            <img
              class="img-fluid right"
              :src="images.sec4.kid2"
              alt="Girl"
            />
          </b-col>
        </b-row>
      </div>
    </div>

    <div class="section-5">
      <div class="sect-wrapper">
        <b-row class="w-100" align-v="center" no-gutters>
          <b-col
            class="text-center"
            cols="12" xl="5"
            order="1" order-md="1" order-lg="1" order-xl="2">
            <div class="sect-content">
              <h1>
                DOWNLOAD
                <img
                  class="ampersand-big"
                  :src="home.ampersandBig"
                  alt="ampersand"
                />
                <br> SIGN UP TODAY
              </h1>
              <br>
              <ul class="list">
                <li>
                  <img
                    class="bullet"
                    :src="home.bullet"
                    alt="bullet"
                  />
                  CREATE VIDEOS
                </li>
                <li>
                  <img
                    class="bullet"
                    :src="home.bullet"
                    alt="bullet"
                  />
                  SAFE SOCIAL MEDIA FOR KIDS</li>
                <li>
                  <img
                    class="bullet"
                    :src="home.bullet"
                    alt="bullet"
                  />
                  EXCLUSIVE MUSIC
                  <img
                    class="ampersand-small"
                    :src="home.ampersand"
                    alt="ampersand"
                  />
                  CONTENT
                </li>
              </ul>

              <b-row
                class="justify-content-center"
                align-v="center"
                no-gutters>
                <b-col cols="auto">
                  <div class="text-right">
                    <img
                      class="img-fluid apple-store"
                      :src="home.appleStore"
                      alt="Apple Store Logo"
                      title="Download Healthy Hip Hop for iOS"
                      @click="redirect(appStoreLink)"
                    />
                    <img
                      class="img-fluid play-store"
                      :src="home.playStore"
                      alt="Play Store Logo"
                      title="Download Healthy Hip Hop for Android"
                      @click="redirect(playStoreLink)"
                    />
                  </div>
                </b-col>
                <b-col cols="auto">
                  <p class="download-text">DOWNLOAD THE APP</p>
                </b-col>
              </b-row>
            </div>
          </b-col>
          <b-col
            class="text-center"
            cols="12" xl="7"
            order="2" order-md="2" order-lg="2" order-xl="1">
            <img
              class="img-fluid mobile-app"
              :src="images.sec5.mobileApp"
              alt="Healthy Hip Hop Mobile App"
            />
          </b-col>
        </b-row>
      </div>
    </div>

    <div class="w-100">
      <AppFooter />
    </div>
    
    <AppLoader :isLoading="loading" />
  </div>
</template>

<script>
  import ImageMixin from '@/mixins/ImageMixin';

  export default {
    head : {
      title : {
        inner      : 'Home',
        separator  : '|',
        complement : 'Healthy Hiphop',
      },
    },
    mixins : [
      ImageMixin,
    ],
    data() {
      return {
        loading      : false,
        appStoreLink : 'https://apps.apple.com/us/app/healthy-hip-hop/' +
          'id1456576616',
        playStoreLink : 'https://play.google.com/store/apps/details?' +
          'id=com.healthyhiphop',
        images : {
          sec1 : {
            pjPanda : require(
              '../../assets/images/home/section-1-landing-page-pj.png'),
            pjPandaFull : require(
              '../../assets/images/home/section-1-landing-page-pj-full.png'),
          },
          sec2 : {
            princess : require(
              '../../assets/images/home/section-2-landing-page-princess.png'),
            squad : require(
              '../../assets/images/home/section-2-landing-page-h3-squad.png'),
          },
          sec3 : {
            dancers1 : require(
              '../../assets/images/home/section-3-landing-page-left-pic.png'),
            dancers2 : require(
              '../../assets/images/home/section-3-landing-page-right-pic.png'),
            squad : require(
              '../../assets/images/home/section-3-landing-page-h3-squad.png'),
          },
          sec4 : {
            kid1 : require(
              '../../assets/images/home/section-4-landing-page-kid-left.png'),
            kid2 : require(
              '../../assets/images/home/section-4-landing-kid-right.png'),
            cubcakes : require(
              '../../assets/images/home/section-4-landing-page-cubcakes.png'),
          },
          sec5 : {
            mobileApp : require(
              '../../assets/images/home/section-5-landing-page-mobile-app.png'),
          },
        },
      }
    },
    components : {
      'AppFooter' : () => import('@/components/layout/AppFooter'),
      'AppLoader' : () => import('@/components/layout/AppLoader'),
      'Sec1'      : () => import('./Section1'),
    },
    methods : {

      /**
       * Redirect page to download H3 App
       * @param link
       */
      redirect(link) {
        if (link == this.appStoreLink) {
          this.$analytics.fbq.event('mobile-download', {
            'page'     : 'landing-page-navbar',
            'platform' : 'website',
            'to'       : 'appstore-ios',
          });
        } else {
          this.$analytics.fbq.event('mobile-download', {
            'page'     : 'landing-page-navbar',
            'platform' : 'website',
            'to'       : 'playstore-android',
          });
        }
        window.open(link, '_target');
      },
    },
    mounted() {
      this.$analytics.fbq.event('PageView', {
        'page'     : 'home-page',
        'platform' : 'website',
      });
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/settings";

  .section-1,
  .section-2,
  .section-3,
  .section-4,
  .section-5 {
    height: fit-content;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  .section-1 {
    background-image: $section1-bg;
    // .pj-panda-crown {
    //   width: 95%;
    // }
    // .pj-panda {
    //   display: none;
    // }
  }

  .section-2 {
    background-image: $section2-bg;
  }

  .section-3 {
    background-image: $section3-bg;
  }

  .section-4 {
    background-image: $section4-bg;
  }

  .section-5 {
    background-image: $section5-bg;
    .list {
      padding-left: 0;
      color: #fff !important;
      font-family: 'WickedMouse';
      font-size: 1.5em;
      list-style-type: none;
      -webkit-text-stroke: 2.5px #0a1d41;
    }
  }

  h1 {
    font-size: 3.25em !important;
    -webkit-text-stroke: 2.5px #0a1d41 !important;
  }

  .sect-row {
    width: 100%;
  }

  .download-text {
    margin-bottom: 0;
    padding-left: 1rem;
    color: #fff;
    font-family: 'WickedMouse' !important;
    font-size: 1.25em !important;
    -webkit-text-stroke: 2px #0a1d41 !important;
  }

  .apple-store,
  .play-store {
    padding: 0 0.25rem;
    width: 3em;
    &:hover {
      cursor: pointer;
    }
  }

  .ampersand-big,
  .ampersand-small,
  .bullet {
    width: 1em;
  }

  /* Extra Small devices */
  @media only screen and (max-width: $xs-max) {
    h1 {
      font-size: 1.7em !important;
      -webkit-text-stroke: 1px #0a1d41 !important;
    }
    .download-text {
      padding-left: 0.3rem;
      font-size: 1em !important;
      -webkit-text-stroke: 1px #0a1d41 !important;
    }
    .apple-store, .play-store {
      padding: 0.15rem;
      width: 2rem;
    }
    .section-1 {
      padding-top: 6rem;
      padding-bottom: 2.5rem;
      .pj-panda {
        display: inline;
        margin-top: 1rem;
        margin-bottom: 1rem;
        width: 55%;
      }
      .pj-panda-crown {
        display: none;
      }
    }
    .section-2 {
      padding-top: 2.5rem;
      text-align: center;
      h1 {
        font-size: 1.8em !important;
        text-align: center;
      }
      .princess {
        margin-bottom: -1rem;
        width: 53%;
      }
      .squad {
        width: 83%;
      }
    }
    .section-3 {
      padding-top: 2.5rem;
      padding-bottom: 2rem;
      text-align: center;
      .left, .right, .middle {
        padding: 0.5rem 0;
        width: 65%;
      }
    }
    .section-4 {
      padding-top: 2.5rem;
      .cubcakes {
        width: 55%;
      }
      .left, .right {
        width: 85%;
      }
    }
    .section-5 {
      padding-top: 2.5rem;
      h1 {
        font-size: 1.75em !important;
      }
      .bullet {
        width: 5%;
      }
      .ampersand-small {
        width: 6%;
      }
      .mobile-app {
        padding-top: 1rem;
        width: 90%;
      }
      .list {
        font-size: 1.2em !important;
        -webkit-text-stroke: 1px #0a1d41 !important;
      }
    }
  }

  /* Small devices (portrait tablets and large phones) */
  @media only screen and (min-width: $sm-min) {
    h1 {
      font-size: 2.5em !important;
      -webkit-text-stroke: 1.75px #0a1d41 !important;
    }
    .download-text {
      padding-left: 0.3rem;
      font-size: 1.15em !important;
      -webkit-text-stroke: 1.5px #0a1d41 !important;
    }
    .section-1 {
      padding-top: 6rem;
      padding-bottom: 2.5rem;
      .pj-panda {
        display: inline;
        margin-top: 1rem;
        margin-bottom: 1rem;
        width: 45%;
      }
      .pj-panda-crown {
        display: none;
      }
    }
    .section-2 {
      padding-top: 2.5rem;
      text-align: center;
      h1 {
        font-size: 2.55em !important;
      }
      .princess {
        margin-bottom: -2rem;
        width: 45%;
      }
      .squad {
        width: 75%;
      }
    }
    .section-3 {
      padding-top: 2.5rem;
      padding-bottom: 2rem;
      text-align: center;
      .left, .right, .middle {
        padding: 0.3rem 0;
        width: 55%;
      }
    }
    .section-4 {
      padding-top: 2.5rem;
      .cubcakes {
        width: 55%;
      }
      .left, .right {
        width: 75%;
      }
    }
    .section-5 {
      padding-top: 2.5rem;
      h1 {
        font-size: 2.55em !important;
        -webkit-text-stroke: 1.75px #0a1d41 !important;
      }
      .list {
        font-size: 1.35em !important;
        -webkit-text-stroke: 1.5px #0a1d41 !important;
      }
    }
  }

  /* Medium devices (landscape tablets) */
  @media only screen and (min-width: $md-min) {
    h1 {
      font-size: 2.75em !important;
      -webkit-text-stroke: 1.75px #0a1d41 !important;
    }
    .download-text {
      padding-left: 0.3rem;
      font-size: 1.15em !important;
      -webkit-text-stroke: 1.5px #0a1d41 !important;
    }
    .section-1 {
      .pj-panda {
        width: 40%;
      }
      .pj-panda-crown {
        display: none;
      }
    }
    .section-2 {
      padding-top: 2.75rem;
      text-align: center;
      h1 {
        font-size: 2.95em !important;
      }
      .princess {
        margin-bottom: -2rem;
        width: 35%;
      }
      .squad {
        width: 60%;
      }
    }
    .section-3 {
      padding-top: 2.75rem;
      padding-bottom: 2.25rem;
      .left, .right, .middle {
        padding: 0.75rem 0;
        width: 45%;
      }
    }
    .section-4 {
      padding-top: 2.75rem;
      .cubcakes {
        padding-bottom: 1.5rem;
        width: 45%;
      }
      .left, .right {
        width: 65%;
      }
    }
    .section-5 {
      padding-top: 2.75rem;
      h1 {
        font-size: 2.95em !important;
      }
      .mobile-app {
        padding-top: 1.75rem;
        width: 80%;
      }
    }
  }

  /* Large devices (laptops/desktops) */
  @media only screen and (min-width: $lg-min) {
    h1 {
      font-size: 3em !important;
      -webkit-text-stroke: 2px #0a1d41 !important;
    }
    .download-text {
      padding-left: 0.5rem;
      font-size: 1.5em !important;
      -webkit-text-stroke: 1.5px #0a1d41 !important;
    }
    .section-1 {
      .pj-panda {
        width: 30%;
      }
    }
    .section-2 {
      padding-top: 3rem;
      h1 {
        font-size: 3.25em !important;
      }
      .princess {
        margin-bottom: -2rem;
        width: 30%;
      }
      .squad {
        width: 50%;
      }
    }
    .section-3 {
      padding-top: 3rem;
      padding-bottom: 2.75rem;
      .left, .right, .middle {
        padding: 1rem 0;
        width: 40%;
      }
    }
    .section-4 {
      padding-top: 3rem;
      .cubcakes {
        padding-bottom: 1.5rem;
        width: 40%;
      }
      .left, .right {
        width: 55%;
      }
    }
    .section-5 {
      padding-top: 3rem;
      h1 {
        font-size: 3.25em !important;
      }
      .list {
        padding-bottom: 1.5rem;
        font-size: 1.75em !important;
      }
      .mobile-app {
        width: 70%;
      }
    }
  }

  /* Extra large devices (large laptops and desktops) */
  @media only screen and (min-width: $xl-min) {
  .download-text {
      padding-left: 0.5rem;
      font-size: 1.3em !important;
      -webkit-text-stroke: 1.5px #0a1d41 !important;
    }
    .section-1 {
      padding-top: 8rem;
      padding-bottom: 0;
      .pj-panda {
        display: none;
      }
      .pj-panda-crown {
        display: inline;
        width: 65%;
      }
    }
    .section-2 {
      h1 {
        margin-left: -6rem;
        width: fit-content;
      }
      .princess {
        margin-bottom: 0;
        width: 70%;
        text-align: left;
      }
      .squad {
        width: 75%;
      }
    }
    .section-3 {
      h1 {
        margin-bottom: 3rem;
      }
      .left, .right, .middle {
        padding: 0;
        width: 80%;
      }
    }
    .section-4 {
      .cubcakes {
        padding-bottom: 3rem;
        width: 60%;
      }
      .left, .right {
        width: 85%;
      }
    }
    .section-5 {
      .sect-content {
        margin-left: -5rem;
      }
      .mobile-app {
        padding-top: 0;
        width: 90%;
      }
    }
  }

  @media only screen and (min-width: $special) {
    .download-text {
      padding-left: 0.5rem;
      font-size: 1.3em !important;
      -webkit-text-stroke: 1.5px #0a1d41 !important;
    }
    .section-1 {
      padding-top: 8rem;
      .pj-panda-crown {
        width: 65%;
      }
    }
  }

  @media only screen and (min-width: $xl2-min) {
    h1 {
      font-size: 3.25em !important;
      -webkit-text-stroke: 2.5px #0a1d41 !important;
    }
    .sect-wrapper {
      margin: 0 auto;
      width: 100rem;
    }
    .section-1 {
      padding-top: 11rem;
      .pj-panda-crown {
        width: 70%;
      }
    }
    .section-2,
    .section-3,
    .section-4,
    .section-5 {
      padding-top: 3.5rem;
    }
    .section-3 {
      .left, .right, .middle {
        width: 85%;
      }
    }
    .section-5 {
      .sect-content {
        margin-left: -10rem;
      }
    }
  }

  /* iMac Res */
  @media only screen and (min-width: $xl5-min) {
    .sect-wrapper {
      margin: 0 auto;
      width: 150rem;
    }
    .section-5 {
      .sect-content {
        margin-left: -15rem;
        margin-bottom: 10rem;
      }
    }
  }
</style>