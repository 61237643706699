const regKid1 = require('../assets/images/kid_register_with_panda.png');
const regKid2 = require('../assets/images/kid_register_without_panda.png');
const regParent1 = require('../assets/images/parent_register_with_monkey.png');
const regParent2 =
  require('../assets/images/parent_register_without_monkey.png');
const regEduc1 = require('../assets/images/educator_register_with_roy.png');
const regEduc2 = require('../assets/images/educator_register_without_roy.png');

/**
 * Home Page
 */
const bullet = require('../assets/images/home/bullet.png');
const ampersand = require('../assets/images/home/ampersand.png');
const ampersandBig = require('../assets/images/home/ampersand-big.png');
const appleStore = require('../assets/images/home/applestore-logo.png');
const playStore = require('../assets/images/home/playstore-logo.png');

export default {
  data() {
    return {
      reg : {
        kid1    : regKid1,
        kid2    : regKid2,
        parent1 : regParent1,
        parent2 : regParent2,
        educ1   : regEduc1,
        educ2   : regEduc2,
      },
      home : {
        bullet       : bullet,
        ampersand    : ampersand,
        ampersandBig : ampersandBig,
        appleStore   : appleStore,
        playStore    : playStore,
      },
    }
  },
}